import React from "react"
import PropTypes from "prop-types"
import { graphql, Link, StaticQuery } from "gatsby"
import { Accordion } from "@mostlycode/ui"
import styled from "styled-components"
import { NavList } from "./NavList"
import { navigationChildrenResolver } from "./helper"

const StyledNavItem = styled.li`
  font-size: 1.6rem;
  text-transform: uppercase;
`

const StyledNavLink = styled(Link)`
  padding: 1rem 1.5rem;
  font-family: ${(props) => props.theme.fontStacks.heading};
  font-size: 1.6rem;
  color: ${(props) => props.theme.colors.kelp};
  letter-spacing: 0.1rem;
`

function NavItem({ url, text, nav_children, ...props }) {
  return (
    <StyledNavItem {...props}>
      <StyledNavLink to={url}>{text}</StyledNavLink>
    </StyledNavItem>
  )
}

export default NavItem

NavItem.defaultProps = {}
NavItem.propTypes = {
  children: PropTypes.node,
}
