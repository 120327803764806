import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { OffCanvas } from '@mostlycode/ui'
import { NavigationContext } from 'context'
import { useLockBodyScroll } from 'hooks/useLockBodyScroll'

const StyledOffCanvasNavigation = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1 1 50%;
  justify-content: space-between;
  background: white;
  position: relative;
`

const StyledFooter = styled.footer`
  padding: 2rem 0 0 2rem;
`

const StyledNavToggle = styled.div`
  position: absolute;
  top: 1rem;
  right: 2rem;
  z-index: 100;
`

const StyledNavInner = styled.nav`
  position: absolute;
  top: 4rem;
  right: 2rem;
  left: 2rem;
`

function OffCanvasNav({ navToggle, nav, children }) {
  const toggled = useContext(NavigationContext)
  useLockBodyScroll(toggled)
  return (
    <OffCanvas selector="#___offcanvas" show={toggled} fromRight>
      <StyledOffCanvasNavigation>
        <StyledNavToggle>
          {navToggle}
        </StyledNavToggle>
        <StyledNavInner>
          {nav}
        </StyledNavInner>
        <StyledFooter>{children}</StyledFooter>
      </StyledOffCanvasNavigation>
    </OffCanvas>
  )
}

export default OffCanvasNav

OffCanvasNav.defaultProps = {
  children: null,
}

OffCanvasNav.propTypes = {
  navToggle: PropTypes.node.isRequired,
  nav: PropTypes.node.isRequired,
  children: PropTypes.node,
}
