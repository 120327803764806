import React from "react"
import { graphql } from "gatsby"
import NavList from "./NavList"

function NavigationList({ nav: { navigation_items } }) {
  return <NavList top_level items={navigation_items} />
}

export default NavigationList

export const query = graphql`
  fragment SiteNavigation on PRISMIC_Site_settings {
    main_navigation {
      ... on PRISMIC_Navigation {
        navigation_items {
          navigation_children {
            _linkType
            ... on PRISMIC_Navigation {
              navigtion_title
              _meta {
                id
                lang
                type
              }
            }
          }
          navigation_link {
            ... on PRISMIC_Page {
              title
              _meta {
                id
                lang
                uid
                type
              }
            }
          }
          navigation_link_text
        }
      }
    }
  }
`
