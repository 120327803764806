const fonts = [
  {
    source: `static`,
    fonts: [
      {
        filename: `barlow`,
        title: `Barlow`,
        config: {
          weights: [`light`],
        },
      },
      {
        filename: `ptserif`,
        title: `PT Serif`,
        config: {
          weights: [`regular`],
        },
      },
    ],
  },
]

const fontStacks = {
  heading: `'PT Serif regular', serif`,
  body: `'Barlow light', sans-serif`,
}

export { fonts, fontStacks }
