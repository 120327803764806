import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  body {
    background: ${(props) => props.theme.colors.kelp};
    font-size: 1.8rem;
  }

  address {}

  article {}

  aside {}

  footer {}

  header {}

  main {}

  nav {}

  section {}

  img { width: 100%; display: block; }

  h1 {
    margin: .5em 0 .25em;
    font-family: ${(props) => props.theme.fontStacks.heading};
    font-size: 4.2rem;
    letter-spacing: 0.1rem;

    & ~ p {
      margin-top: .5em;
      font-size: 2.1rem;
      line-height: 3rem;
      letter-spacing: 0.025rem;
    }
  }

  h2 {
    font-size: 2.6rem;
    line-height: 3.8rem;
    font-family: ${(props) => props.theme.fontStacks.heading};
    font-weight: 500;

    + p {
      margin-top: 1.2em;
    }
  }

  p {
    margin: 0;
    font-size: 1.7rem;
    line-height: 2.3rem;

     + p {
       margin-top: 1em;
     }
  }

  a {
    color: currentColor;
    text-decoration: none;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    h1 {
      margin: .5em 0 .25em;
      font-size: 4.2rem;
      line-height: 1.15;
      letter-spacing: 0.1rem;

      /* & ~ p {
        margin-top: .5em;
        font-size: 2.1rem;
        line-height: 3rem;
        letter-spacing: 0.025rem;
      } */
    }

    h2 {
      font-size: 3.6rem;
      line-height: 4.8rem;

      & ~ p {
        margin-top: .5em;
        font-size: 2.1rem;
        line-height: 3rem;
        letter-spacing: 0.025rem;
      }
    }
  `}

`

export { GlobalStyles }
