const colors = {
  primary: "#3E5956",
  secondary: "#8FA69D",
  white: "#FFFFFF",
  beige: "#F2EDDF",
  kelp: "#1F332F",
  cherry: "#8C4653",
  ocher: "#EF9A29"
}

export { colors }
