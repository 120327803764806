import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledLegal = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.ice};
  font-size: 1.2rem;
  margin-top: 4rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    // padding: 1rem;
  `}
`

function Legal({ copyright_text }) {
  const year = new Date()
  const copyright = `\u{00A9} ${year.getFullYear()}`
  return (
    <StyledLegal>
      <span>{copyright}</span>
      <span>{`. ${copyright_text}`}</span>
    </StyledLegal>
  )
}

export default Legal

Legal.defaultProps = {
  copyright_text: "All rights reserved.",
}

Legal.propTypes = {
  copyright_text: PropTypes.string,
}
