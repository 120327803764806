import { colors } from './colors'

const buttons = {
  primary: {
    background: colors.poppy,
    color: colors.plush,
    border: `3px solid ${colors.secondary}`,
    hover: {
      borderColor: colors.secondary,
    },
  },
  secondary: {
    background: colors.primary,
    color: colors.plush,
    border: `3px solid ${colors.primary}`,
    hover: {
      borderColor: colors.primary,
    },
  },
  danger: {
    background: colors.cherry,
    color: colors.white,
    border: `3px solid ${colors.cherry}`,
    hover: {
      borderColor: colors.cherry,
    },
  },
  plush: {
    background: colors.plush,
    color: colors.kelp,
    border: `3px solid ${colors.primary}`,
    hover: {
      borderColor: colors.primary,
    },
  },
  default: {
    background: colors.white,
    color: colors.kelp,
    border: `3px solid ${colors.primary}`,
    hover: {
      borderColor: colors.primary,
    },
  },
  transparent: {
    background: `transparent`,
    color: "currentColor",
    border: `3px solid ${colors.white}`,
    hover: {
      borderColor: colors.white,
    },
  },
}

export { buttons }
