import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Headroom from "react-headroom"
import { Icon } from "@mostlycode/ui"
import Container from "components/Container"

const StyledHeader = styled.header`
  padding: 2rem;
  background: ${(props) => props.theme.colors.white};
  transition: all ease-out 0.2s;
  height: 8.8rem;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);

  .headroom--pinned & {
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.kelp};
    box-shadow: 0px 5px 20px -1px rgba(0, 0, 0, 0.2);
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 0 2rem;
  `}

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    display: inline-block;
    .logo-icon {
      height: 2.4rem;
      width: 20rem;
      fill: ${(props) => props.theme.colors.kelp};

      ${(props) => props.theme.media.tablet_landscape_up`
        height: 4rem;
        width: auto;
      `}
    }
  }
`
function Header({ children, ...props }) {
  return (
    <Headroom style={{ zIndex: "2000" }}>
      <StyledHeader {...props}>
        <Container className="container">
          <Link className="logo" to="/">
            <Icon
              className="logo-icon"
              icon="logo-primary-horisontal"
              size="auto"
            />
          </Link>
          {children}
        </Container>
      </StyledHeader>
    </Headroom>
  )
}

export default Header
