/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { GlobalStyles, FontFamilies } from "@mostlycode/ui/theme"
import { GlobalStyles as SiteGlobalStyles } from "theme/global"
import { siteTheme } from "theme"
import styled, { ThemeProvider } from "styled-components"
import { NavToggle, NavUntoggle } from "@mostlycode/ui"

import { NavigationContext, ViewportContext } from "context"
import { isViewport } from "helpers"
import useViewport from "hooks/useViewport"

import Header from "components/Header"
import Navigation from "components/Navigation"
import OffCanvasNav from "components/OffCanvasNav"
import Footer from "components/Footer"

const StyledWrapper = styled.div`
  background: ${(props) => props.theme.colors.white};
`

const StyledNavToggle = styled(NavToggle)`
  border: none;
  background: transparent;
  transition: all ease-out 0.2s;
  color: ${(props) => props.theme.colors.black};
  margin: 0;

  .headroom--pinned & {
    color: ${(props) => props.theme.colors.kelp};
  }

  &:active {
    background: none;
  }

  &:focus {
    outline: none;
    background: none;
  }
`

const StyledNavUntoggle = styled(NavUntoggle)`
  border: none;
  background: transparent;
  transition: all ease-out 0.2s;
  color: ${(props) => props.theme.colors.kelp};
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }
`

const StyledMain = styled.main`
  position: relative;
  z-index: 1000;
  ${(props) => props.theme.media.desktop_up`
    // max-width: 120rem;
    // margin: 0 auto;
    margin-top: 0;
  `}
`

const StyledHeader = styled(Header)`
  position: relative;
  z-index: 1200;
`

const Layout = ({ site_settings: { main_navigation }, children }) => {
  const [navToggled, setNavToggled] = useState(false)
  const viewport = useViewport()

  function handleNavToggle() {
    setNavToggled((prev) => !prev)
  }

  return (
    <ThemeProvider theme={siteTheme}>
      <FontFamilies />
      <GlobalStyles />
      <SiteGlobalStyles />
      <ViewportContext.Provider value={viewport}>
        <NavigationContext.Provider value={navToggled}>
          <StyledWrapper>
            <StyledHeader>
              {!isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]) ? (
                <nav>
                  <Navigation nav={main_navigation} />
                </nav>
              ) : (
                <nav>
                  <StyledNavToggle onToggle={handleNavToggle} />
                </nav>
              )}
            </StyledHeader>
            <StyledMain>{children}</StyledMain>
            <Footer />
          </StyledWrapper>
          {isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]) && (
            <OffCanvasNav
              navToggle={<StyledNavUntoggle onToggle={handleNavToggle} />}
              nav={<Navigation nav={main_navigation} />}
            ></OffCanvasNav>
          )}
        </NavigationContext.Provider>
      </ViewportContext.Provider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
