import { theme } from '@mostlycode/ui/theme'
import { buttons } from './buttons'
import { colors } from './colors'
import { fonts, fontStacks } from './fonts'

const siteTheme = {
  ...theme,
  colors,
  fonts: fonts,
  fontStacks: fontStacks,
  buttonStyles: buttons,
  baseBodyColor: `#1F332F`,
  linkColor: colors.primary,
  fontSizes: {
    p: "1.7rem",
    h1: "4.2rem",
    h2: "3.6rem",
    h3: "2.8rem",
    h4: "2.6rem",
    h5: "2.4rem",
    h6: "1.8rem",
  }
}

export { siteTheme }
