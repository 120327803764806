import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledContainer = styled.div`
  ${(props) => props.theme.media.tablet_landscape_up`
    max-width: 120rem;
    padding: 2rem 2rem;
    margin: 0 auto;

    & + & {
      margin-top: 2rem;
      border-top: 1px solid #eee;
    }
  `}
`

function Container({ children, ...props }) {
  return <StyledContainer {...props}>{children}</StyledContainer>
}

export default Container

Container.defaultProps = {}
Container.propTypes = {
  children: PropTypes.node.isRequired,
}
