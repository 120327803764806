import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { linkResolver } from "utils/linkResolver"
import NavItem from "./NavItem"

const StyledNavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  ${(props) => props.theme.media.tablet_landscape_up`
    display: flex;
  `}
`

function NavList({ top_level, items, ...props }) {
  function renderChildren() {
    return (
      <>
        {items.map((item) => {
          const {
            navigation_link,
            navigation_children,
            navigation_link_text,
          } = item
          const key = navigation_link._meta.id
          const link_text =
            navigation_link_text || navigation_link?.title[0].text
          const url = linkResolver(navigation_link._meta)
          return (
            <NavItem
              key={key}
              url={url}
              text={link_text}
              nav_children={navigation_children}
            />
          )
        })}
      </>
    )
  }

  // The 'top_level' property allows us to reuse NavList component recursively, but render an unstyled <ul /> for child-levels, to allow great degree of customisation
  return top_level ? (
    <StyledNavList {...props}>{renderChildren()}</StyledNavList>
  ) : (
    <ul {...props}>{renderChildren()}</ul>
  )
}

export default NavList

NavList.defaultProps = {
  top_level: false,
}
NavList.propTypes = {
  top_level: PropTypes.bool,
  children: PropTypes.node,
}
