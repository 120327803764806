import React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import { Icon } from "@mostlycode/ui"
import Container from "components/Container"
import Content from "components/Content"
import NavList from "components/Navigation/NavList"
import Legal from "./Legal"
import SocialMediaLinks from "./SocialMediaLinks"

const StyledFooter = styled.footer`
  padding: 8rem 2rem;
  background: ${(props) => props.theme.colors.kelp};
  color: ${(props) => props.theme.colors.white};
  clip-path: polygon(0% 3%, 100% 0%, 100% 100%, 0% 100%);

  h4 {
    font-size: 1.4rem;
    font-family: ${(props) => props.theme.fontStacks.heading};
    text-transform: uppercase;
    margin: 0 0 1em;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    clip-path: polygon(0% 3%, 100% 0%, 100% 100%, 0% 100%);
  `}
`

const StyledInfo = styled.div``

const StyledLogo = styled(Link)`
  display: block;
  max-width: 13.4rem;

  svg {
    transition: fill ease-out 0.2s;
    width: 24rem;
    height: 4rem;
    fill: ${(props) => props.theme.colors.beige};
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    svg {
      transition: fill ease-out 0.2s;
      width: 30rem;
      height: 4rem;
      fill: ${(props) => props.theme.colors.beige};
    }
`}
`

const StyledAddress = styled.address`
  font-style: normal;
  color: ${(props) => props.theme.colors.white};
  // margin-top: 2rem;

  a {
    color: ${(props) => props.theme.colors.primary};
  }

  p {
    margin: 0;
    line-height: 1.7;
    font-size: 1.4rem;

    &:empty {
      display: block;
      height: 1em;
    }
  }

  h4 {
    margin-top: 0;
    margin-bottom: 1rem;

    ${(props) => props.theme.media.tablet_landscape_up`
      line-height: 1em;
      margin-bottom: 2rem;
    `}
  }
`

const StyledInnerFooter = styled.div`
  display: grid;
  grid-row-gap: 3rem;
  padding-top: 4rem;

  .nav-list + .nav-list {
    margin-top: 4rem;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    grid-template-columns: 1fr 1.5fr 1.5fr 1fr;
  `}
`

const StyledNavs = styled.nav`
  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 0 4rem;
  `}
`

const StyledNavlist = styled(NavList)`
  margin: 0;
  list-style: none;
  padding: 0;

  li {
    line-height: 1.5;
    padding: 0.5rem 0;

    a {
      font-size: 1.6rem;
      font-weight: normal;
      margin: 0;
      padding: 0;
      border: none;
      color: #c5c5c5;
      text-transform: none;
      &.active,
      &:hover {
        border: none;
      }
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    li {

      a {
        min-height: 0;
      }
    }
  `}
`

function Footer({
  footer_information,
  footer_navigations,
  social_media_links,
  lang,
  children,
  ...props
}) {
  return (
    <StyledFooter>
      <Container>
        <StyledLogo to={"/"}>
          <Icon
            icon="logo-primary-horisontal-no-color"
            title="Team Business Logo"
          />
        </StyledLogo>
        <StyledInnerFooter>
          <StyledInfo>
            <StyledAddress>
              <Content content={footer_information} />
            </StyledAddress>
          </StyledInfo>
          <StyledNavs className="primary">
            {footer_navigations &&
              footer_navigations.map((nav, i) => {
                if (i > 0) return null
                const {
                  footer_navigation_title,
                  footer_navigation: { navigation_items },
                } = nav
                return (
                  <div className="nav-list" key={`nav_${i}`}>
                    <h4>{footer_navigation_title}</h4>
                    <StyledNavlist items={navigation_items} />
                  </div>
                )
              })}
          </StyledNavs>
          <StyledNavs className="secondary">
            {footer_navigations &&
              footer_navigations.map((nav, i) => {
                if (i === 0) return null
                const {
                  footer_navigation_title,
                  footer_navigation: { navigation_items },
                } = nav
                return (
                  <div className="nav-list" key={`nav_${i}`}>
                    <h4>{footer_navigation_title}</h4>
                    <StyledNavlist items={navigation_items} />
                  </div>
                )
              })}
          </StyledNavs>
          <SocialMediaLinks links={social_media_links} />
        </StyledInnerFooter>
        <Legal />
      </Container>
    </StyledFooter>
  )
}

export default Footer

export const query = graphql`
  fragment FooterNavigation on PRISMIC_Site_settings {
    footer_navigations {
      footer_navigation {
        ... on PRISMIC_Navigation {
          navigation_items {
            navigation_children {
              ... on PRISMIC_Navigation {
                navigtion_title
                _meta {
                  id
                  lang
                  type
                }
              }
            }
            navigation_link {
              ... on PRISMIC_Page {
                title
                _meta {
                  lang
                  uid
                  type
                }
              }
            }
            navigation_link_text
          }
        }
      }
      footer_navigation_title
    }
  }
`
